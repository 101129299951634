// import just the functions you want to use - Firebase 9
import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getAuth } from "firebase/auth"


const firebaseConfig = {
    apiKey: "AIzaSyDZ6Yyqqt9RvI4P2f4A7u-aBSSDarZZLBI",
    authDomain: "aiqanda.firebaseapp.com",
    projectId: "aiqanda",
    storageBucket: "aiqanda.appspot.com",
    messagingSenderId: "536917887620",
    appId: "1:536917887620:web:a7cf3e7e83211e9748d0d1",
    measurementId: "G-4WHX7MK6G5"
  };

// init firebase
initializeApp(firebaseConfig)

// init services
// invoke getFirestore function
// use anything else instead of db
// this is now the dtabase object
const db = getFirestore()
const auth = getAuth()

// export db object so we can use it in project
export { db, auth }