<template>


	<div class="top" v-if="user">
		<div id="w-node-_0758deea-3ce6-826c-8987-81a7e127818d-e127818c" class="button-container">
			<router-link to="/a" class="button transparent w-inline-block">NOLU</router-link>
			
		</div>
		<div id="w-node-_0758deea-3ce6-826c-8987-81a7e1278192-e127818c" class="button-container">
			
			
			<router-link to="/account" class="button transparent w-inline-block">Account</router-link>
			
		</div>
	</div>

	<div class="top" v-if="!user">
		<div id="w-node-_0758deea-3ce6-826c-8987-81a7e127818d-e127818c" class="button-container">
			<div><router-link to="/" class="button transparent w-inline-block">NOLU</router-link></div>
		</div>
		<div id="w-node-_0758deea-3ce6-826c-8987-81a7e1278192-e127818c" class="button-container">
			
			<router-link to="/signup" class="button transparent w-inline-block">Signup</router-link>
			<router-link to="/login" class="button transparent w-inline-block">Login</router-link>
		</div>
	</div>


</template>

<script>
	import getUser from '../composables/getUser'
	
	// import { watchEffect } from 'vue'

	// firebase imports
	// import { auth } from '../firebase/config'
	// import { signOut } from 'firebase/auth'
	// import { getAnalytics, logEvent } from "firebase/analytics";



	export default {

		setup() {

			const { user } = getUser()
			

		

			return {
				
				user
			}
		}
	}
</script>

<style>
	nav {
		display: flex;
		align-items: center;
	}

	nav h1 {
		margin-right: auto;
		margin-bottom: 0;
	}

	nav a {
		margin-left: 16px;
		color: #2c3e50;
	}

	nav button {
		margin-left: 16px;
	}

	nav a.router-link-exact-active {
		color: #0ec58e;
	}

	nav+p {
		margin-top: 0;
		margin-bottom: 30px;
	}
</style>