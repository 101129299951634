import { createRouter, createWebHistory } from 'vue-router'

// view components
// import Home from '../views/Home.vue'
// import Login from '../views/Login.vue'
// import Signup from '../views/Signup.vue'
// import App from '../views/Landing.vue'
// import Thanks from '../views/Thanks.vue'
// import Account from '../views/Account.vue'


// firebase imports
import { auth } from '../firebase/config'

const requireAuth = (to, from, next) => {
	let user = auth.currentUser
	if (!user) {
		next({
			name: 'App'
		})
	} else {
		next()
	}
}



const routes = [
	
	{

		path: '/a',
		name: 'NOLU AI',
		component: ()=> import(/* webpackChunkName: "Home" */ '../views/Home.vue'),
		beforeEnter: requireAuth,


		meta: {

			title: 'NOLU — App',
			metaTags: [{
					
					name: 'description',
					content: 'A simple interface for interacting with the GPT-3 AI.'
				},
				{
					property: 'og:type',
					content: 'website'
				},
				{
					property: 'og:url',
					content: 'https://noluai.com/a'
				},
				{
					property: 'og:title',
					content: 'NOLU — App'
				},
				{
					property: 'og:image',
					content: 'https://firebasestorage.googleapis.com/v0/b/aiqanda.appspot.com/o/nolu-og-1.png?alt=media&token=4896a3c2-c4bf-405c-bcef-2227e6a270a6'
				},
				{
					property: 'twitter:card',
					content: 'summary_large_image'
				},
				{
					property: 'twitter:url',
					content: 'https://noluai.com/a'
				},
				{
					property: 'twitter:title',
					content: 'NOLU — App'
				},
				{
					property: 'twitter:description',
					content: 'A simple interface for interacting with the GPT-3 AI. '
				},
				{
					property: 'twitter:image',
					content: 'https://firebasestorage.googleapis.com/v0/b/aiqanda.appspot.com/o/nolu-og-1.png?alt=media&token=4896a3c2-c4bf-405c-bcef-2227e6a270a6'
				}
			]

		}

	},
	{
		
		path: '/login',
		name: 'Login',
		component: ()=> import(/* webpackChunkName: "Login" */ '../views/Login.vue'),

		meta: {

			title: 'NOLU — Login',
			metaTags: [{
					
					name: 'description',
					content: 'A simple interface for interacting with the GPT-3 AI.'
				},
				{
					property: 'og:type',
					content: 'website'
				},
				{
					property: 'og:url',
					content: 'https://noluai.com/login'
				},
				{
					property: 'og:title',
					content: 'NOLU — Login'
				},
				{
					property: 'og:image',
					content: 'https://firebasestorage.googleapis.com/v0/b/aiqanda.appspot.com/o/nolu-og-1.png?alt=media&token=4896a3c2-c4bf-405c-bcef-2227e6a270a6'
				},
				{
					property: 'twitter:card',
					content: 'summary_large_image'
				},
				{
					property: 'twitter:url',
					content: 'https://noluai.com/login'
				},
				{
					property: 'twitter:title',
					content: 'NOLU — Login'
				},
				{
					property: 'twitter:description',
					content: 'A simple interface for interacting with the GPT-3 AI. '
				},
				{
					property: 'twitter:image',
					content: 'https://firebasestorage.googleapis.com/v0/b/aiqanda.appspot.com/o/nolu-og-1.png?alt=media&token=4896a3c2-c4bf-405c-bcef-2227e6a270a6'
				}
			]

		}

	},
	{

		path: '/',
		name: 'App',
		component: ()=> import(/* webpackChunkName: "Landing" */ '../views/Landing.vue'),

		meta: {

			title: 'NOLU — Home',
			metaTags: [{
					
					name: 'description',
					content: 'A simple interface for interacting with the GPT-3 AI.'
				},
				{
					property: 'og:type',
					content: 'website'
				},
				{
					property: 'og:url',
					content: 'https://noluai.com/'
				},
				{
					property: 'og:title',
					content: 'NOLU — Home'
				},
				{
					property: 'og:image',
					content: 'https://firebasestorage.googleapis.com/v0/b/aiqanda.appspot.com/o/nolu-og-1.png?alt=media&token=4896a3c2-c4bf-405c-bcef-2227e6a270a6'
				},
				{
					property: 'twitter:card',
					content: 'summary_large_image'
				},
				{
					property: 'twitter:url',
					content: 'https://noluai.com/a'
				},
				{
					property: 'twitter:title',
					content: 'NOLU — Home'
				},
				{
					property: 'twitter:description',
					content: 'A simple interface for interacting with the GPT-3 AI. '
				},
				{
					property: 'twitter:image',
					content: 'https://firebasestorage.googleapis.com/v0/b/aiqanda.appspot.com/o/nolu-og-1.png?alt=media&token=4896a3c2-c4bf-405c-bcef-2227e6a270a6'
				}
			]

		}

	},
	{

		path: '/thanks',
		name: 'Thanks',
		component: ()=> import(/* webpackChunkName: "Thanks" */ '../views/Thanks.vue'),

		
		meta: {

			title: 'NOLU — Thanks',
			metaTags: [{
					
					name: 'description',
					content: 'Thanks for visiting. Enjoy your day!'
				},
				{
					property: 'og:type',
					content: 'website'
				},
				{
					property: 'og:url',
					content: 'https://noluai.com/thanks'
				},
				{
					property: 'og:title',
					content: 'NOLU — Thanks'
				},
				{
					property: 'og:image',
					content: 'https://firebasestorage.googleapis.com/v0/b/aiqanda.appspot.com/o/nolu-og-1.png?alt=media&token=4896a3c2-c4bf-405c-bcef-2227e6a270a6'
				},
				{
					property: 'twitter:card',
					content: 'summary_large_image'
				},
				{
					property: 'twitter:url',
					content: 'https://noluai.com/thanks'
				},
				{
					property: 'twitter:title',
					content: 'NOLU — Thanks'
				},
				{
					property: 'twitter:description',
					content: 'Thanks for visiting. Enjoy your day! '
				},
				{
					property: 'twitter:image',
					content: 'https://firebasestorage.googleapis.com/v0/b/aiqanda.appspot.com/o/nolu-og-1.png?alt=media&token=4896a3c2-c4bf-405c-bcef-2227e6a270a6'
				}
			]
		}

	},
	{

		path: '/signup',
		name: 'Signup',
		component: ()=> import(/* webpackChunkName: "Signup" */ '../views/Signup.vue'),

		meta: {
			
			title: 'NOLU — Signup',
			metaTags: [{
					
				name: 'description',
				content: 'Welcome! Create an account to start interacting with Ai.'
			},
			{
				property: 'og:type',
				content: 'website'
			},
			{
				property: 'og:url',
				content: 'https://noluai.com/signup'
			},
			{
				property: 'og:title',
				content: 'NOLU — Signup'
			},
			{
				property: 'og:image',
				content: 'https://firebasestorage.googleapis.com/v0/b/aiqanda.appspot.com/o/nolu-og-1.png?alt=media&token=4896a3c2-c4bf-405c-bcef-2227e6a270a6'
			},
			{
				property: 'twitter:card',
				content: 'summary_large_image'
			},
			{
				property: 'twitter:url',
				content: 'https://noluai.com/signup'
			},
			{
				property: 'twitter:title',
				content: 'NOLU — Signup'
			},
			{
				property: 'twitter:description',
				content: 'Welcome! Create an account to start interacting with Ai.'
			},
			{
				property: 'twitter:image',
				content: 'https://firebasestorage.googleapis.com/v0/b/aiqanda.appspot.com/o/nolu-og-1.png?alt=media&token=4896a3c2-c4bf-405c-bcef-2227e6a270a6'
			}
		]
		}

	},
	{

		path: '/account',
		name: 'Account',
		component: ()=> import(/* webpackChunkName: "Account" */ '../views/Account.vue'),
		beforeEnter: requireAuth,

		meta: {
			title: 'NOLU — Account',
			metaTags: [{
					
				name: 'description',
				content: 'My Account'
			},
			{
				property: 'og:type',
				content: 'website'
			},
			{
				property: 'og:url',
				content: 'https://noluai.com/account'
			},
			{
				property: 'og:title',
				content: 'NOLU — Account'
			},
			{
				property: 'og:image',
				content: 'https://firebasestorage.googleapis.com/v0/b/aiqanda.appspot.com/o/nolu-og-1.png?alt=media&token=4896a3c2-c4bf-405c-bcef-2227e6a270a6'
			},
			{
				property: 'twitter:card',
				content: 'summary_large_image'
			},
			{
				property: 'twitter:url',
				content: 'https://noluai.com/account'
			},
			{
				property: 'twitter:title',
				content: 'NOLU — Account'
			},
			{
				property: 'twitter:description',
				content: 'My Account '
			},
			{
				property: 'twitter:image',
				content: 'https://firebasestorage.googleapis.com/v0/b/aiqanda.appspot.com/o/nolu-og-1.png?alt=media&token=4896a3c2-c4bf-405c-bcef-2227e6a270a6'
			}
		]
		}

	},
	{ 
		
		path: "/:catchAll(.*)", 
		name:"404", 
		
		component: ()=> import(/* webpackChunkName: "404" */ '../views/404.vue'), 
		
		meta: {
			title: 'NOLU — 404',
			metaTags: [{
					
				name: 'description',
				content: 'Page not found.'
			},
			{
				property: 'og:type',
				content: 'website'
			},
			{
				property: 'og:url',
				content: 'https://noluai.com/'
			},
			{
				property: 'og:title',
				content: 'NOLU — 404'
			},
			{
				property: 'og:image',
				content: 'https://firebasestorage.googleapis.com/v0/b/aiqanda.appspot.com/o/nolu-og-1.png?alt=media&token=4896a3c2-c4bf-405c-bcef-2227e6a270a6'
			},
			{
				property: 'twitter:card',
				content: 'summary_large_image'
			},
			{
				property: 'twitter:url',
				content: 'https://noluai.com/'
			},
			{
				property: 'twitter:title',
				content: 'NOLU — 404'
			},
			{
				property: 'twitter:description',
				content: 'Page not found.'
			},
			{
				property: 'twitter:image',
				content: 'https://firebasestorage.googleapis.com/v0/b/aiqanda.appspot.com/o/nolu-og-1.png?alt=media&token=4896a3c2-c4bf-405c-bcef-2227e6a270a6'
			}
		]
		}
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router