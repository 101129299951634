<template>


	<div class="app">

		<Navbar />
		<router-view />
		

	</div>


</template>


<script>


import Navbar from './components/Navbar.vue'

export default {
	components: {
		Navbar
	},

	// mounted() {
	// 	let Script = document.createElement("script");
	// 	Script.setAttribute("src", "./js/noluai-asdaq332f3lkfe.js");
	// 	document.head.appendChild(Script);
	// }


	
}

</script>


<style>
body {
	font-family: 'IBM Plex Sans', sans-serif;
}

@import './assets/css/normalize.css';
@import './assets/css/noluai-asdaq332f3lkfe.css';
@import './assets/css/components.css';






@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;500;600;700&family=IBM+Plex+Sans:wght@400;500;600;700&family=IBM+Plex+Serif:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

.textarea.w-input:focus {
	outline: 0px !important;
	-webkit-appearance: none;
	box-shadow: none !important;
	outline: none;
	border-color: #ffffff;
}

;
</style>